import * as React from "react";
import { Link } from "gatsby";
import { Parallax } from "@react-spring/parallax";
import { Themed } from "theme-ui";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider";
import {
  UpDown,
  UpDownWide,
} from "@lekoarts/gatsby-theme-cara/src/styles/animations";
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg";
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo";
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content";
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner";

const Agree = () => (
  <Layout>
    <Seo title="利用規約" />
    <Inner>
      <Themed.h1>利用規約</Themed.h1>

      <p>
        この利用規約（以下，「本規約」といいます。）は，Minerva株式会社（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
      </p>
      <p>
        第1条（適用） *
        本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        *当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
        *本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        *ユーザーは当アプリを利用する際に表示される利用規約に同意し、「始める」ボタンを押した際又は本アプリの機能を利用した際に本利用規約に同意したものとします。
        *当社は本アプリの保守、運用、開発、マーケティング等の目的にユーザー情報を収集でき、また第三者に提供できるものとします。
      </p>
      <p>
        第2条（不適切な利用について） *
        当社は不適切な利用に対し以下の対処を行うことができる。当アプリの利用によって生成された結果に関しても適用されるものとする。
        * ユーザーを利用停止にできる。 *
        不適切な利用・当アプリによって生成された結果を停止させることができ、応じない場合は一件当たり金10万円を請求できるものとする。
        *
        その他，当社が利用登録を相当でないと判断した場合、利用を停止させることができる。
        * 当社が不適切な利用と判断したものを不適切なものとします。
      </p>
      <p>
        第3条（責任の範囲について） *
        当社は当アプリの利用によって生じたいかなる損害、損失等の一切の責任を負わないものとします。
        *
        当社は当社が原因となり生じた問題に関しても一切の責任を負わず、またいかなる損害、損失を補償しないものとします。
      </p>
      <p>
        第4条（禁止事項）
        ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。 *
        法令または公序良俗に違反する行為 * 犯罪行為に関連する行為 *
        本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
        *
        当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
        * スクレイピング等サーバーに負担を負荷を掛ける行為 *
        本サービスによって得られた情報を商業的に利用する行為 *
        当社のサービスの運営を妨害するおそれのある行為 *
        不正アクセスをし，またはこれを試みる行為 *
        不正な目的を持って本サービスを利用する行為 *
        当アプリをデリバースエンジニアリング・デコンパイルをする行為、又は使用と試みる行為
        *
        本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
        * 当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為 *
        当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
        * その他，当社が不適切と判断する行為
      </p>
      <p>
        第5条（本サービスの提供の停止等） *
        当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        * 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合 *
        地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
        * コンピュータまたは通信回線等が事故により停止した場合 *
        その他，当社が本サービスの提供が困難と判断した場合 *
        当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
      </p>
      <p>
        第6条（利用制限および登録抹消） *
        当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
        * 本規約のいずれかの条項に違反した場合 *
        登録事項に虚偽の事実があることが判明した場合 *
        当社からの連絡に対し，一定期間返答がない場合 *
        本サービスについて，最終の利用から一定期間利用がない場合 *
        その他，当社が本サービスの利用を適当でないと判断した場合 *
        当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
      </p>
      <p>
        第7条（保証の否認および免責事項） *
        当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
        *
        当社は，本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
        *
        前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，金1円を上限とします。
        *
        当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
      </p>
      <p>
        第8条（サービス内容の変更等）
        当社は，ユーザーへ告知を行うことなく、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
      </p>
      <p>
        第9条（利用規約の変更） *
        当社は、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
      </p>
      <p>
        第10条（個人情報の取扱い）
        当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
      </p>
      <p>
        第11条（通知または連絡）
        ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。
      </p>
      <p>
        第12条 (本サービスの売却・譲渡について)
        当社はユーザーに告知をすることなく本サービスを売却・譲渡することができる。
        その際、当社はユーザーに関する情報等も譲渡することができる。
      </p>
      <p>
        第13条（権利義務の譲渡の禁止）
        ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
      </p>
      <p>
        第14条（準拠法・裁判管轄） *
        本規約の解釈にあたっては，日本法を準拠法とします。 *
        本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
        以上
      </p>
    </Inner>
  </Layout>
);

export default Agree;
